const initialState = {
  isCardRevealed: false,
  hasCardBeenRevealed: false,
  questions: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_CARD_REVEALED": {
      return {
        ...state,
        isCardRevealed: action.payload.isCardRevealed,
        hasCardBeenRevealed: true,
      }
    }
    case "RESET_CARD_REVEALED": {
      return {
        ...state,
        isCardRevealed: false,
        hasCardBeenRevealed: false,
      }
    }
    case "GET_QUESTIONS": {
      return {
        ...state,
        questions: action.payload.questions,
      }
    }
    default: {
      return state
    }
  }
}
