/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "normalize.css"
import "./src/styles/global.scss"
import storeProvider from "./src/utils/storeProvider"

export const wrapRootElement = storeProvider
