// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-lesson-js": () => import("/opt/build/repo/src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-templates-challenge-js": () => import("/opt/build/repo/src/templates/challenge.js" /* webpackChunkName: "component---src-templates-challenge-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lambda-js": () => import("/opt/build/repo/src/pages/lambda.js" /* webpackChunkName: "component---src-pages-lambda-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

